:root {
  --primary-color: #194cff;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "@angular/cdk/overlay-prebuilt.css";

html {
  -webkit-tap-highlight-color: transparent;
}

@layer components {
  .island-base {
    @apply rounded-md bg-white shadow-[0px_4px_24px_rgba(0,0,0,0.08)];
  }

  .auth-button {
    @apply button button-primary w-full py-[18px] text-lg font-bold leading-[22px]
    disabled:bg-opacity-70;
  }

  .island {
    @extend .island-base;
    @apply p-5;
  }

  .link {
    @apply text-accent transition-colors duration-200 hover:text-accent/70
    hover:underline hover:underline-offset-2 focus-visible:text-accent/70
    focus-visible:underline focus-visible:underline-offset-2;
  }

  .button-base {
    @apply select-none border-transparent transition-all
    duration-200 hover:border-opacity-50 focus-visible:border-opacity-50
    active:opacity-50 disabled:pointer-events-none disabled:opacity-60;
  }

  .button {
    @apply button-base box-content flex justify-center rounded-md;

    &-primary {
      @apply border bg-accent text-white;
    }

    &-destructive {
      @apply border bg-red text-white;
    }

    &-outline {
      @apply border;
    }

    &-rounded {
      @apply button-base flex items-center justify-center rounded-full;
    }
  }

  .time-ago {
    @apply ml-[3px] text-sm leading-[22px] text-black/30  before:content-['∙'];
  }

  .title {
    @apply text-2xl font-bold leading-[29px] text-black;
  }

  .info-title {
    @apply text-center text-[32px] font-bold text-black;
  }

  .form-field {
    @apply w-full rounded-lg border-2 border-transparent
    bg-black/5 p-5 text-sm font-medium leading-[20px] text-black outline-none placeholder:text-black/50 focus-visible:border-accent;
  }

  .image-dropdown {
    @apply after:pointer-events-none after:opacity-0 after:duration-300 after:content-[""];
    &_active {
      @apply after:absolute after:inset-x-0
        after:inset-y-0 after:bg-gradient-to-b
        after:from-black/80 after:to-black/0
        focus-within:after:opacity-100 after:hover:opacity-100;
    }
    &_focus {
      @apply after:opacity-100;
    }
  }
}

@layer utilities {
  .break-word {
    word-break: break-word;
  }
  .scrollbar {
    scrollbar-width: thin;
    scrollbar-color: black #e8e8e8;

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    &::-webkit-scrollbar-button {
      width: 0px;
      height: 0px;
    }
    &::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 8px 8px #1a1a1a;
      border: solid 3px transparent;
      border-radius: 20px;
    }
    &::-webkit-scrollbar-thumb {
      &:hover,
      &:active {
        box-shadow: inset 0 0 8px 8px #525252;
      }
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 8px 8px #e8e8e8;
      margin-block: 4px;
      border: solid 3px transparent;
      border-radius: 20px;
    }
    &::-webkit-scrollbar-corner {
      background: transparent;
    }
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

@mixin ff($family, $url, $weight) {
  @font-face {
    font-family: quote($family);
    font-weight: $weight;
    src: url("./assets/fonts/" + $url);
    font-display: swap;
  }
}

@include ff(Inter, "Inter-Regular.ttf", 400);
@include ff(Inter, "Inter-Medium.ttf", 500);
@include ff(Inter, "Inter-Bold.ttf", 700);

* {
  @apply outline-accent;
}
/* html {
  @apply scroll-smooth;
} */

body {
  @apply m-auto h-screen w-full max-w-[1440px] bg-bg;
}

.ng-dirty.ng-invalid:not(form):not(.p-inputwrapper):not(:focus),
.input-error {
  @apply border-rose-500;
}

.sw-app-icon {
  background-image: url("assets/img/icons/app.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.sw-brush-icon {
  background-image: url("assets/img/icons/brush.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.sw-cross-square-icon {
  background-image: url("assets/img/icons/cross-square.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.sw-human-icon {
  background-image: url("assets/img/icons/human.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.sw-logout-icon {
  background-image: url("assets/img/icons/logout.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.sw-qr-code-icon {
  background-image: url("assets/img/icons/qr-code.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.sw-settings-icon {
  background-image: url("assets/img/icons/settings.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.cdk-global-overlay-wrapper {
  overflow: overlay;
  pointer-events: auto;
}
.cdk-dialog-container {
  outline: none;
}
