.p-avatar {
  user-select: none;
}
.p-dialog {
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  transform: scale(1);
  position: relative;
  border: 2rem solid transparent;
  margin: auto;
  border-radius: 15px;
  max-height: initial !important;
  box-shadow: none;

  .p-dialog-header {
    display: flex;
    flex-direction: row;
    padding: 20px 14px 20px 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    .p-dialog-header-icon {
      width: 24px;
      height: 24px;
      padding: 18px;
      &:focus {
        background-color: rgb(26 26 26 / 0.05);
        box-shadow: none;
      }
    }
    .p-dialog-title {
      font-weight: bold;
      color: #1a1a1a;
      font-size: 24px;
      line-height: 29px;
    }
  }
  .p-dialog-content {
    overflow-y: initial;
    padding: 20px;
    &:last-of-type {
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
    }
  }
  &:not(.p-dialog-header) {
    .p-dialog-content {
      &:first-of-type {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
      }
    }
  }
}

@media (max-width: 767px) {
  .p-dialog {
    border-bottom: none;
    border-left: none;
    border-right: none;
    margin-bottom: unset;
    width: 100% !important;
    transform: scale(1) !important;
  }
  .p-dialog .p-dialog-content:last-of-type {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .p-component-overlay {
    background-color: rgba(0, 0, 0, 0.7) !important;
  }
}

/*
.p-dialog {
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  transform: scale(1);
  position: relative;
  border: 2rem solid transparent;
  margin: auto;
} */

.p-dialog-mask.p-component-overlay {
  pointer-events: auto;
}

.p-dialog-mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: auto;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  pointer-events: none;
  overflow: overlay;
}

.p-chips {
  border-radius: 15px;
  overflow: hidden;

  &:focus-within {
    outline: 2px solid #194cff;
  }

  .p-chips-multiple-container {
    gap: 10px;
    padding: 20px;
    border: none;

    background-color: rgb(26 26 26 / 0.05);

    ::placeholder {
      color: rgb(26 26 26 / 0.5);
    }

    .p-chips-input-token {
      padding: 0;
      input {
        color: #1a1a1a;

        font-weight: 500;
        font-size: 15px;
        line-height: 19px;
      }
    }
    .p-chips-token {
      color: #1a1a1a;
      border-radius: 10px;
      background: #f6f6f9;
      border: 1px solid rgba(26, 26, 26, 0.1);
      font-size: 14px;
      line-height: 17px;
      padding: 5px 10px;
      color: #1a1a1a;

      &:has(.chips-token-error) {
        background-color: rgba(207, 53, 53, 0.3);
      }
    }
  }

  .p-chips-multiple-container:not(.p-disabled).p-focus {
    outline: 2px solid #194cff;
    outline-offset: 0;
    box-shadow: none;
  }
}

.p-inputswitch {
  width: 63px;
  height: 33px;
  .p-inputswitch-slider,
  &.p-inputswitch-checked .p-inputswitch-slider {
    background: rgba(26, 26, 26, 0.05);
    border: 1px solid rgba(26, 26, 26, 0.1);
    border-radius: 1000px;
  }
  &:not(.p-disabled):hover .p-inputswitch-slider,
  &.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
    background: rgba(26, 26, 26, 0.1);
  }

  &.p-focus .p-inputswitch-slider {
    background-color: rgb(26 26 26 / 0.05);
    box-shadow: none;
  }

  .p-inputswitch-slider {
    &:before {
      background: rgba(26, 26, 26, 0.2);

      width: 28px;
      height: 28px;
      margin-top: 0;
      transform: translateY(-50%);
    }
  }

  &:focus-within .p-inputswitch-slider {
    outline: 2px solid #194cff;
  }

  &.p-inputswitch-checked .p-inputswitch-slider {
    &::before {
      transform: translate(98%, -50%);

      background: #194cff;
      left: 3px;
    }
  }
}

.p-dropdown {
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.05);
  border: none;
  padding: 20px 4px 20px 20px;

  &:not(.p-disabled).p-focus {
    outline: 2px solid #194cff;
    box-shadow: none;
    border: none;
  }

  .p-dropdown-trigger {
    color: rgba(0, 0, 0, 0.23);
  }

  .p-dropdown-label.p-placeholder {
    color: rgba(26, 26, 26, 0.5);
  }

  .p-dropdown-label {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    padding: 0 10px 0 0;
  }
  .p-inputtext {
    color: #1a1a1a;
  }
}

.p-inputwrapper.ng-dirty.ng-invalid:not(form):not(:focus-within) {
  .p-component {
    box-shadow: 0 0 0 2px red inset;
  }
}

.p-slider {
  border-radius: 0;
  .p-slider-handle {
    width: 16px;
    height: 16px;

    background: #ffffff;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.35);
    border-radius: 9999px;
    border-color: transparent;

    &:focus {
      border-color: var(--slider-color);
      box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.35);
    }
  }

  .p-slider-range {
    background: var(--slider-color);
  }
  &:not(.p-disabled) .p-slider-handle:hover {
    background: var(--slider-color);
    border-color: var(--slider-color);
  }
}

.p-image.p-image-preview-container {
  border-radius: 10px;
  overflow: hidden;
}

.p-toast:has(.sw-toast) {
  width: max-content;
}

.p-toast .sw-toast {
  .p-toast-icon-close {
    color: inherit !important;
  }
  &.p-toast-message {
    border: none;
    background: #ffffff;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.24);
    border-radius: 10px;
    color: #1a1a1a;
  }

  .p-toast-message-content .p-toast-message-text {
    margin: 0;
  }
  .p-toast-message-content {
    align-items: center;
    border-width: 0;
    padding: 10px 20px;
  }
  .p-toast-message-icon {
    display: none;
  }
  .p-toast-summary:empty + .p-toast-detail {
    margin-top: 0;
  }

  .p-toast-detail {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #1a1a1a;
  }
}

.p-accordion {
  .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
    box-shadow: none;
    outline: 2px solid #194cff;
  }

  .p-icon-wrapper,
  .icon {
    color: #194cff;
  }

  p-accordiontab .p-accordion-tab {
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.08);
  }

  .p-accordion-content {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .p-accordion-header .p-accordion-header-link {
    border: none;
    background-color: #ffffff;
    border-radius: 10px;
  }

  .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link,
  .p-accordion-header .p-accordion-header-link,
  .p-accordion-header-link {
    color: #1a1a1a;
  }

  .p-accordion-header-text {
    color: inherit;
    text-align: center;
    font-size: 18px;
    font-family: Inter;
    font-weight: 700;
  }

  .p-accordion-content {
    border: none;
  }
}

.p-checkbox .p-checkbox-box {
  border: 2px solid #d1d5db;

  &:hover,
  &:focus {
    border-color: #194cff;
  }

  &.p-highlight {
    border-color: #194cff;
    background: #194cff;
  }
}

.dialog-animation {
  animation-name: dialog-from-bottom;
  animation-duration: 600ms;
  animation-delay: 300ms;
}

.p-menu .p-menuitem:not(.p-highlight):not(.p-disabled) {
  &.p-focus > .p-menuitem-content {
    background: unset;
  }

  & > .p-menuitem-content {
    &:hover,
    &:focus-within {
      color: #495057;
      background: #e9ecef;
    }
  }
}

.p-radiobutton {
  .p-radiobutton-box {
    &:not(.p-disabled):not(.p-highlight):hover {
      border-color: #194cff;
    }

    &.p-highlight {
      border-color: #194cff;
      background: #fff;

      &:not(.p-disabled):hover {
        background-color: #194cff;
        border-color: #194cff;

        .p-radiobutton-icon {
          background-color: #ffffff;
        }
      }
    }
    &:not(.p-disabled).p-focus {
      border-color: #194cff;

      box-shadow: none;
    }

    .p-radiobutton-icon {
      background-color: #194cff;
      width: 14px;
      height: 14px;
    }
  }
}

.pi {
  &-primary {
    color: #194cff !important;
  }

  &-error {
    color: #cf3535 !important;
  }
}

.user-content-upload.p-menu {
  z-index: 1;
  width: 216px;
  border-top-width: 10px;
  border-bottom-width: 10px;
  border-style: solid;
  border-color: transparent;
  background-color: transparent;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

  & .p-menu-list {
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
    --tw-bg-opacity: 1;
    background-color: #ffffff;
    padding-top: 5px;
    padding-bottom: 5px;
    --tw-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    --tw-shadow-colored: 0px 2px 8px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .p-menuitem-text {
    font-size: 12px;
  }
}

/* SCROLLPANEL */

.tx-scrollpanel {
  .p-scrollpanel-wrapper {
    border-right: 2px solid #eeeeee;
  }
  /* .p-scrollpanel-content {
    padding: 0 42px 20px 0;
  } */

  .p-scrollpanel-bar {
    opacity: 1;
    background-color: #194cff;
    border-radius: 4px;
    width: 0;
    border: 1px solid #194cff;
    margin-left: -2px;
  }

  .p-scrollpanel-bar-y {
    visibility: visible;
    height: 44%;
    top: calc(0% - 7px);
    right: -282px;
    transition: height 0.3s ease;
  }
}
/* p-scrollpanel-content	Content section.
p-scrollpanel-bar	Scrollbar handle.
p-scrollpanel-bar-x	Scrollbar handle of a horizontal bar.
p-scrollpanel-bar-y	Scrollbar handle of a vertical bar */
