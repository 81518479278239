.shepherd {
  &-element {
    border-radius: 16px;
    background-color: #ffffff;
  }

  &-footer {
    justify-content: space-between;
    align-items: center;

    &:not(:has(> .button + .button)) .button {
      margin-left: auto;
    }
  }
}
